@tailwind base;

@tailwind components;

@tailwind utilities;



html, body {
    height:100%;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Open Sans', sans-serif;
}
blockquote {
    font-family: 'Playfair Display', serif;
}

@media only screen and (min-width: 768px) {
    .trim {
        height:100%;
        height:calc(100% - 26rem);
    }
}
@media(min-width:1024px) {
    .max-h-none {
        max-height: none;
    }
}

:target {
    display: block;
    position: relative;
    top: -100px; 
    visibility: hidden;
}

